/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "askkrishna",
            "endpoint": "https://bguszcu2ai.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:ae8caad9-e4bc-4c3e-845e-96cb63ee9c92",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YPJRNnMz3",
    "aws_user_pools_web_client_id": "3hqqasqfk2umd3mrgd9arij554",
    "oauth": {
        "domain": "askkrishna7ab14931-7ab14931-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://main.d1j89xkpx7fgzs.amplifyapp.com/,https://www.sovatt.com/",
        "redirectSignOut": "http://localhost:3000/,https://main.d1j89xkpx7fgzs.amplifyapp.com/,https://www.sovatt.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ask-krishna-full-conversations71618-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
